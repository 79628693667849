<template>
  <div class="page">
    <HomeHero />
    <div class="section container">
      <h1 class="title is-size-1">
        Uh-oh!
      </h1>
      <p class="subtitle">
        Looks like that page doesn't exist.
      </p>
      <p class="subtitle">
        Try selecting a directory above to find what you're looking for!
      </p>
    </div>
  </div>
</template>

<script>
import HomeHero from '@/components/Global/HomeHero.vue';

export default {
  components: {
    HomeHero,
  },
};
</script>
